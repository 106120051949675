

















import { Patient, ResourceTopic } from '@/models';
import { computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { routeNames } from '@/router/routeNames';
import { storage } from '@/main';
import { useRouter } from '@/router';

export default defineComponent({
  name: 'ViewTopicsPage',
  setup() {
    const topics = ref<ResourceTopic[]>([]);
    const { router } = useRouter();

    const currentUser = computed(() => storage.auth.asPatient);

    const headers = [
      { text: 'Name', value: 'data.name' },
      { text: '', value: 'actions' },
    ];

    const loadTopics = async () => {
      const patient = await Patient.builder().with('patientLocation').find(currentUser.value.uuid);

      topics.value = await patient.location.client.resourceTopics().getAll();
    };

    const goToItem = (item: ResourceTopic) => {
      router.push({ name: routeNames.patient.viewResourceTopic, params: { uuid: item.uuid } });
    };

    onMounted(() => {
      loadTopics();
    });

    return {
      topics,
      headers,
      goToItem,
    };
  },
});
